import { APP_BASE_HREF, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatToolbar } from '@angular/material/toolbar';
import { Title } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { AppInsightsService, AppSettingsService } from '@shared/util/infrastructure';
import { NgStringPipesModule } from 'ngx-pipes';

@Component({
  selector: 'lsf-page-not-found',
  templateUrl: './page-not-found.component.html',
  standalone: true,
  imports: [TranslocoModule, MatToolbar, MatDivider, RouterLink, MatAnchor, NgStringPipesModule, NgIf],
})
export class PageNotFoundComponent implements OnInit {
  constructor(
    private title: Title,
    private translocoService: TranslocoService,
    public appSettingsService: AppSettingsService,
    @Inject(APP_BASE_HREF) public baseHref: string,
  ) {}

  ngOnInit(): void {
    this.title.setTitle(this.translocoService.translate('common._sentences.Page_not_found_title'));
    AppInsightsService.trackTraceError(`Could not find route ${window.location.href}`);
  }
}
