import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { loginUrl, storeCurrentUrl } from '@shared/feature/auth';
import { CurrentOrImpersonateUser, UserService } from '@shared/util/user';

@Injectable({
  providedIn: 'root',
})
export class RoleRouter {
  private readonly loggedInUser: CurrentOrImpersonateUser;
  constructor(
    private router: Router,
    userService: UserService,
  ) {
    this.loggedInUser = userService.currentOrImpersonateUser;
  }

  canActivate(_: unknown, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.loggedInUser.isAuthenticated) {
      return this.getUrl(this.loggedInUser.role);
    }
    storeCurrentUrl(state.url);
    return this.router.parseUrl(loginUrl);
  }

  getUrl(role: string) {
    switch (role) {
      default: {
        return this.router.parseUrl(`/portal/landing-page`);
      }
    }
  }
}
